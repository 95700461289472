import { makeAutoObservable } from "mobx"

interface Modal {
    open: boolean;
    body: JSX.Element | null;
    closable: boolean;
    modalTitle: string;
}

export default class ModalStore {
    confirmModalOpen: boolean = false;
    modal: Modal = {
        open: false,
        body: null,
        closable: true,
        modalTitle: ""
    }

    constructor() {
        makeAutoObservable(this)
    }

    openConfirmModal = () => {
        this.confirmModalOpen = true;
    }
    closeConfirmModal = () => {
        this.confirmModalOpen = false;
    }

    openModal = (content: JSX.Element) => {
        this.modal.open = true;
        this.modal.body = content;
    }

    openFormModal = (content: JSX.Element, title: string) => {
        this.modal.open = true;
        this.modal.body = content;
        this.modal.closable = false;
        this.modal.modalTitle = title;
    }

    closeModal = () => {
        if (this.modal.closable) {
            this.modal.open = false;
            this.modal.body = null;
            this.modal.modalTitle = "";
        }
    }
}