import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { RestingPlaceDTO, DeleteRestingPlaceDTO } from '../../app/models/models';
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import { toast } from 'react-toastify'
import { history } from "../..";
import CreateRestingPlace from "./CreateRestingPlace";
import UpdateRestingPlace from "./UpdateRestingPlace";

export default observer(function RestingPlacesIndex() {
    const { restingPlaceStore } = useStore();
    const [restingPlaces, setRestingPlaces] = useState<RestingPlaceDTO[]>([]);
    const [filteredRestingPlaces, setFilteredRestingPlaces] = useState<RestingPlaceDTO[]>([]);
    const [search, setSearch] = useState('');
    const [regionDropdown, setRegionDropdown] = useState('');


    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);
    const [createRestingPlaceModal, setCreateRestingPlaceModal] = useState<boolean>(false);
    const [updateRestingPlaceModal, setUpdateRestingPlaceModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    const listPerPage = 5;

    useEffect(() => {
        const fetchData = async () => {

            await restingPlaceStore.loadRestingPlaces();
            if (restingPlaceStore.restingPlaces) {
                setRestingPlaces(JSON.parse(JSON.stringify(restingPlaceStore.restingPlaces.restingPlaceList)));
                setFilteredRestingPlaces(JSON.parse(JSON.stringify(restingPlaceStore.restingPlaces.restingPlaceList)));
                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(restingPlaceStore.restingPlaces.restingPlaceList)))).length / listPerPage))
            }
        }
        fetchData()
            .catch(console.error);
    }, [])

    const handleFilter = () => {
        if (search == '' && regionDropdown == '') {
            toast.error("En az bir adet arama kriteri giriniz.");
        }
        else {
            var rp = restingPlaces.filter((item) => {
                return search?.toLowerCase() == '' ? item : item.name?.toLowerCase().includes(search);
            }).filter((item) => {
                return regionDropdown == 'all' || regionDropdown == '' ? item : item.region?.toLowerCase() == regionDropdown.toLowerCase();
            })
            setCurrentPage(1);
            setPageCount(Math.ceil(rp.length / listPerPage))
            setFilteredRestingPlaces(rp);
            if (rp.length == 0) {
                toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
                setIsThereData(false)
            }
        }

    }

    const clearFilters = () => {
        setCurrentPage(1);
        setIsThereData(true);
        setFilteredRestingPlaces(restingPlaces);
        setPageCount(Math.ceil(restingPlaces.length / listPerPage));
        setSearch('');
        setRegionDropdown('');
    }

    const closeCreateRestingPlaceModal = () => {
        setCreateRestingPlaceModal(false);
    }

    const closeUpdateRestingPlaceModal = () => {
        setUpdateRestingPlaceModal(false);
    }





    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    async function ConfirmRemoveSubmit() {
        try {
            let rid = new DeleteRestingPlaceDTO();
            rid.id = elementId;
            var res = await restingPlaceStore.delete(rid);
            if (res === 200) {
                setRemoveConfirmModalOpen(false);
                toast.success('Dosya başarıyla kaldırılmıştır.')
                await setTimeout(() => history.go(0), 1000);
            }
            else {
                toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
            }
        }
        catch (error) {
            //console.log(error);
        }
    }


    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }

    const handleRemove = (id: string) => {
        setElementId(id);
        setRemoveConfirmModalOpen(true);
    }


    const handleUpdate = (id: string) => {
        setElementId(id);
        setUpdateRestingPlaceModal(true);

    }

    const handleSetSearch = (result: any) => {
        setSearch(result)
        setCurrentPage(1);
    }

    const handleRegionDropdown = (value: any) => {
        setRegionDropdown(value.toString());
    }



    if (restingPlaceStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>KISA BİR MOLA</span>
                </div>
                <div className='RestingPlacesBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'850px'} body={<CreateRestingPlace handleClose={closeCreateRestingPlaceModal} />} modalTitle='Mola Yerleri' open={createRestingPlaceModal} closeModal={closeCreateRestingPlaceModal} />
                    <ModalInnerContainer width={'850px'} body={<UpdateRestingPlace elementId={elementId} handleClose={closeUpdateRestingPlaceModal} />} modalTitle='Mola Yerleri' open={updateRestingPlaceModal} closeModal={closeUpdateRestingPlaceModal} />

                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Mola yerini kaldırmak istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Tamam"
                        onCancel={handleCancel}
                        onConfirm={() => { ConfirmRemoveSubmit(); }}
                        size='mini'
                    />

                    <div className="" style={{ background: 'white' }}>
                        <div className="approverFilter">
                            <div className="restingPlaceSearch" style={{ width: '180px', marginRight: '15px' }} >
                                <p>Mola Yeri Ara</p>

                                <div className="ui category search" style={{ width: '180px' }}>
                                    <div className="ui icon input" style={{ width: '180px ' }} >
                                        <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Ara" onChange={(event) => handleSetSearch(event.target.value)} value={search}></input>
                                        <i className="search icon"></i>
                                    </div>
                                    <div className="results"></div>
                                </div>
                            </div>
                            <div className="regionDropdown " style={{ width: '300px', marginRight: '15px' }} >
                                <p>Bölge Seçiniz</p>
                                <Dropdown style={{
                                    width: '300px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'Akdeniz', text: "Akdeniz Bölgesi", value: "Akdeniz" },
                                    { key: 'Karadeniz', text: "Karadeniz Bölgesi", value: "Karadeniz" },
                                    { key: 'Marmara', text: "Marmara Bölgesi", value: "Marmara" },
                                    { key: 'İç Anadolu', text: "İç Anadolu Bölgesi", value: "İç Anadolu" },
                                    { key: 'Ege', text: "Ege Bölgesi", value: "Ege" },
                                    { key: 'Doğu Anadolu', text: "Doğu Anadolu Bölgesi", value: "Doğu Anadolu" },
                                    { key: 'Güneydoğu Anadolu', text: "Güneydoğu Anadolu Bölgesi", value: "Güneydoğu Anadolu" },
                                    ]}
                                    value={regionDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            handleRegionDropdown(value);
                                        }
                                    }}
                                />

                            </div>

                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button  history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button  confirm-button' onClick={() => { setCreateRestingPlaceModal(true) }} type='submit' value='Ekle' />
                            </div>

                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />
                            </div>

                            : <div className="VideoCardList" >
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>Mola Yerleri</th>
                                            <th>Ortak Hizmetler</th>
                                            <th>Bölge</th>
                                            <th>Kaldır</th>
                                            <th>Güncelle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRestingPlaces.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.serviceList}</td>
                                                    <td>{item.region} Bölgesi</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleRemove((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'red' }} className="x list-icon icon"></i></button>
                                                    </td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                    {pageCount < 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
                


            </div >
        </>
    )
})