import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { IntroCardDTO, IntroRecommendationDTO, IntroVideoDTO, DeleteIntroDTO } from '../../app/models/models';
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import { toast } from 'react-toastify'
import { history } from "../..";
import CreateIntroCard from "./CreateIntroCard";
import UpdateIntroCard from "./UpdateIntroCard";
import CreateIntroVideo from "./CreateIntroVideo";
import UpdateIntroVideo from "./UpdateIntroVideo";
import CreateIntroRecommendation from "./CreateIntroRecommendation";
import UpdateIntroRecommendation from "./UpdateIntroRecommendation";

export default observer(function IntrosIndex() {
    const { introCardStore, introRecommendationStore, introVideoStore, commonIntroStore } = useStore();
    const [IntroVideos, setIntroVideos] = useState<IntroVideoDTO[]>([]);
    const [filteredIntroVideos, setFilteredIntroVideos] = useState<IntroVideoDTO[]>([]);
    const [IntroCards, setIntroCards] = useState<IntroCardDTO[]>([]);
    const [filteredIntroCards, setFilteredIntroCards] = useState<IntroCardDTO[]>([]);
    const [introRecommendations, setIntroRecommendations] = useState<IntroRecommendationDTO[]>([]);
    const [filteredIntroRecommendations, setFilteredIntroRecommendations] = useState<IntroRecommendationDTO[]>([]);

    const [recDropdownOptions, setRecDropdownOptions] = useState<{ key: string; text: string; value: string; }[]>([]);


    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [tableState, setTableState] = useState<number>(0);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [serviceDropdown, setServiceDropdown] = useState('');
    const [productDropdown, setProductDropdown] = useState('');
    const [sectionDropdown, setSectionDropdown] = useState('');
    const [pDropdownActive, setPDropdownActive] = useState<boolean>(false);
    const [sDropdownActive, setSDropdownActive] = useState<boolean>(false);

    const [createCardModalOpen, setCreateCardModalOpen] = useState<boolean>(false);
    const [updateCardModalOpen, setUpdateCardModalOpen] = useState<boolean>(false);

    const [createVideoModalOpen, setCreateVideoModalOpen] = useState<boolean>(false);
    const [updateVideoModalOpen, setUpdateVideoModalOpen] = useState<boolean>(false);

    const [createRecommendationModalOpen, setCreateRecommendationModalOpen] = useState<boolean>(false);
    const [updateRecommendationModalOpen, setUpdateRecommendationModalOpen] = useState<boolean>(false);


    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');
    const [elementType, setElementType] = useState<string>('');

    const listPerPage = 5;

    useEffect(() => {
        const fetchData = async () => {
            await introVideoStore.loadIntroVideos();
            await introCardStore.loadIntroCards();
            await introRecommendationStore.loadIntroRecommendations();
            if (introVideoStore.introVideos && introCardStore.introCards && introRecommendationStore.introRecommendations) {
                setIntroVideos(JSON.parse(JSON.stringify(introVideoStore.introVideos.introVideoList)));
                setIntroCards(JSON.parse(JSON.stringify(introCardStore.introCards.introCardList)));
                setIntroRecommendations(JSON.parse(JSON.stringify(introRecommendationStore.introRecommendations.introRecommendationList)));
                setFilteredIntroVideos(JSON.parse(JSON.stringify(introVideoStore.introVideos.introVideoList)));
                setFilteredIntroCards(JSON.parse(JSON.stringify(introCardStore.introCards.introCardList)));
                setFilteredIntroRecommendations(JSON.parse(JSON.stringify(introRecommendationStore.introRecommendations.introRecommendationList)));
                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(introVideoStore.introVideos.introVideoList)))).length / listPerPage))
                setTableState(4)
            }
        }
        fetchData()
            .catch(console.error);
        handleFirstRoute()
    }, [])

    const handleFirstRoute = () => {
        handleServiceDropdown('');
        handleProductDropdown('');
        handleSectionDropdown('');
        setPDropdownActive(false)
        setSDropdownActive(false)
    }

    const handleRecDropdownOptions = (value: any) => {
        let items = [];
        let tcv = ''
        if (value == 'Kamyon') { tcv = 'Truck'; }
        else if (value == 'Otobüs') { tcv = 'Bus'; }
        for (let i = 0; i < introRecommendations.length; i++) {
            if (introRecommendations[i].vehicleType == tcv) {
                items.push({ key: introRecommendations[i].section, text: introRecommendations[i].section, value: introRecommendations[i].section });
            }
        }
        setRecDropdownOptions(items)
    }


    const handleFilter = () => {
        setCurrentPage(1);
        if (serviceDropdown == '') {
            toast.error('Lütfen hizmet seçimi yapınız!')
        }
        else {
            if (serviceDropdown == 'Tanıtıcı Videolar') {
                let dts = IntroVideos.filter((item) => {
                    return sectionDropdown == '' ? item : item.section?.toLowerCase() == sectionDropdown.toLowerCase();
                }).filter((item) => {
                    return productDropdown == '' ? item
                        : productDropdown == 'Kamyon' ? item.vehicleType?.toLowerCase() == 'truck'
                            : productDropdown == 'Otobüs' && item.vehicleType?.toLowerCase() == 'bus'

                })
                setTableState(2)
                setCurrentPage(1);
                setPageCount(Math.ceil(dts.length / listPerPage))
                setFilteredIntroVideos(dts);
                if (dts.length == 0) {
                    toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
                    setIsThereData(false)
                }
            }
            else if (serviceDropdown == 'Tanıtım Kartları') {
                let dts = IntroCards.filter((item) => {
                    return sectionDropdown == '' ? item : item.section?.toLowerCase() == sectionDropdown.toLowerCase();
                }).filter((item) => {
                    return productDropdown == '' ? item
                        : productDropdown == 'Kamyon' ? item.vehicleType?.toLowerCase() == 'truck'
                            : productDropdown == 'Otobüs' && item.vehicleType?.toLowerCase() == 'bus'

                })
                setTableState(1)
                setCurrentPage(1);
                setPageCount(Math.ceil(dts.length / listPerPage))
                setFilteredIntroCards(dts);
                if (dts.length == 0) {
                    toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
                    setIsThereData(false)
                }
            }
            else if (serviceDropdown == 'Tavsiye ve Öneriler') {
                let dts = introRecommendations.filter((item) => {
                    return sectionDropdown?.toLowerCase() == '' ? item
                        : item.section?.toLowerCase().includes(sectionDropdown.toLowerCase());
                }).filter((item) => {
                    return productDropdown == '' ? item
                        : productDropdown == 'Kamyon' ? item.vehicleType?.toLowerCase() == 'truck'
                            : productDropdown == 'Otobüs' && item.vehicleType?.toLowerCase() == 'bus'
                })
                setTableState(3)
                setCurrentPage(1);
                setPageCount(Math.ceil(dts.length / listPerPage))
                setFilteredIntroRecommendations(dts);
                if (dts.length == 0) {
                    toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
                    setIsThereData(false)
                }
            }
        }
    }

    const clearFilters = () => {
        handleServiceDropdown('');
        handleProductDropdown('');
        handleSectionDropdown('');
        setTableState(4)
        setCurrentPage(1);
        setPDropdownActive(false);
        setSDropdownActive(false);
        setIsThereData(true);
        setPageCount(Math.ceil(IntroVideos.length / listPerPage));
    }

    const handleServiceDropdown = (value: any) => {
        setServiceDropdown(value.toString());
        setPDropdownActive(true);
    }

    const handleProductDropdown = (value: any) => {
        setProductDropdown(value.toString());
        setSDropdownActive(true)
    }

    const handleSectionDropdown = (value: any) => {
        setSectionDropdown(value.toString());
    }

    const closeCreateCardModal = () => {
        setCreateCardModalOpen(false);
    }
    const closeUpdateCardModal = () => {
        setUpdateCardModalOpen(false);
    }
    const closeCreateVideoModal = () => {
        setCreateVideoModalOpen(false);
    }
    const closeUpdateVideoModal = () => {
        setUpdateVideoModalOpen(false);
    }
    const closeCreateRecommendationModal = () => {
        setCreateRecommendationModalOpen(false);
    }
    const closeUpdateRecommendationModal = () => {
        setUpdateRecommendationModalOpen(false);
    }

    const handleNewEntry = () => {

        if (serviceDropdown == 'Tanıtıcı Videolar') {
            if (productDropdown == '') {
                toast.error('Lütfen ürün grubu seçiniz!');
                return;
            }
            setCreateVideoModalOpen(true);
        }
        else if (serviceDropdown == 'Tanıtım Kartları') {
            if (productDropdown == '') {
                toast.error('Lütfen ürün grubu seçiniz!');
                return;
            }
            setCreateCardModalOpen(true);
        }
        else if (serviceDropdown == 'Tavsiye ve Öneriler') {
            if (productDropdown == '') {
                toast.error('Lütfen ürün grubu seçiniz!');
                return;
            }
            setCreateRecommendationModalOpen(true);
        }
        else {
            toast.error('Lütfen hizmet seçimi yapınız!')
        }
    }


    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }

    const handleRemove = (id: string, type: string) => {
        setElementId(id);
        setElementType(type)
        setRemoveConfirmModalOpen(true);
    }

    async function ConfirmRemoveSubmit() {
        try {
            let rid = new DeleteIntroDTO();
            rid.id = elementId;
            rid.type = elementType;
            var res = await commonIntroStore.deleteIntro(rid);
            if (res === 200) {
                setRemoveConfirmModalOpen(false);
                toast.success('Dosya başarıyla kaldırılmıştır.')
                await setTimeout(() => history.go(0), 1000);
            }
            else {
                toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
            }
        }
        catch (error) {
            //console.log(error);
        }
    }

    const handleUpdate = (id: string, type: string) => {
        setElementId(id);
        if (type == 'Video') { setUpdateVideoModalOpen(true); }
        else if (type == 'Card') { setUpdateCardModalOpen(true); }
        else if (type == 'Recommendation') { setUpdateRecommendationModalOpen(true); }
    }

    if (introVideoStore.loadingInitial || introRecommendationStore.loadingInitial || introCardStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>ARACIMA DAİR</span>
                </div>
                <div className='ApproverBodyContainer' style={{ width: '100%' }}>



                    <ModalInnerContainer width={'500px'} body={<CreateIntroCard vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeCreateCardModal} />} modalTitle='Tanıtım Kartları' open={createCardModalOpen} closeModal={closeCreateCardModal} />
                    <ModalInnerContainer width={'500px'} body={<UpdateIntroCard vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeUpdateCardModal} />} modalTitle='Tanıtım Kartları' open={updateCardModalOpen} closeModal={closeUpdateCardModal} />
                    <ModalInnerContainer width={'500px'} body={<CreateIntroVideo vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeCreateVideoModal} />} modalTitle='Tanıtıcı Videolar' open={createVideoModalOpen} closeModal={closeCreateVideoModal} />
                    <ModalInnerContainer width={'500px'} body={<UpdateIntroVideo vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeUpdateVideoModal} />} modalTitle='Tanıtıcı Videolar' open={updateVideoModalOpen} closeModal={closeUpdateVideoModal} />
                    <ModalInnerContainer width={'500px'} body={<CreateIntroRecommendation vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeCreateRecommendationModal} />} modalTitle='Tavsiye ve Öneriler' open={createRecommendationModalOpen} closeModal={closeCreateRecommendationModal} />
                    <ModalInnerContainer width={'500px'} body={<UpdateIntroRecommendation vehicleType={productDropdown == 'Otobüs' ? 'Bus' : 'Truck'} elementId={elementId} handleClose={closeUpdateRecommendationModal} />} modalTitle='Tavsiye ve Öneriler' open={updateRecommendationModalOpen} closeModal={closeUpdateRecommendationModal} />

                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Dosyayı kaldırmak istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Tamam"
                        onCancel={handleCancel}
                        onConfirm={() => { ConfirmRemoveSubmit(); }}
                        size='mini'
                    />
                    <div className="" style={{ background: 'white' }}>
                        <div className="approverFilter">
                            <div className="serviceDropdown " style={{ width: '220px', marginRight: '15px' }} >
                                <p>Hizmet</p>

                                <Dropdown style={{
                                    width: '220px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'videos', text: "Tanıtıcı Videolar", value: "Tanıtıcı Videolar" },
                                    { key: 'cards', text: "Tanıtım Kartları", value: "Tanıtım Kartları" },
                                    { key: 'suggestions', text: "Tavsiye ve Öneriler", value: "Tavsiye ve Öneriler" },
                                    ]}
                                    value={serviceDropdown}
                                    onChange={(event, { text, value }) => {
                                        if (value != null) {
                                            handleServiceDropdown(value);
                                        }
                                    }}
                                />

                            </div>
                            <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    disabled={!pDropdownActive}
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={productDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            handleProductDropdown(value);
                                            handleRecDropdownOptions(value);
                                        }
                                    }}
                                />

                            </div>
                            {serviceDropdown == 'Tavsiye ve Öneriler' ?

                                <div className="sectionDropdown " style={{ width: '250px', marginRight: '15px' }} >
                                    <p>Bölüm</p>
                                    <Dropdown style={{
                                        width: '250px',
                                        backgroundColor: 'rgb(242,242,242)',
                                        height: '43px',
                                        margin: '0 auto',
                                        fontSize: '16px',
                                    }}
                                        placeholder='Seçiniz'
                                        fluid
                                        selection
                                        search
                                        disabled={!sDropdownActive}
                                        options={recDropdownOptions}
                                        value={sectionDropdown}
                                        onChange={(event, { value }) => {
                                            if (value != null) {
                                                handleSectionDropdown(value);
                                            }
                                        }}
                                    />

                                </div>
                                :
                                <div className="sectionDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                    <p>Bölüm</p>
                                    <Dropdown style={{
                                        width: '180px',
                                        backgroundColor: 'rgb(242,242,242)',
                                        height: '43px',
                                        margin: '0 auto',
                                        fontSize: '16px',
                                    }}
                                        placeholder='Seçiniz'
                                        fluid
                                        selection
                                        disabled={!sDropdownActive}
                                        options={[{ key: 'cabin', text: "Kabin", value: "Cabin" },
                                        { key: 'drive', text: "Sürüş", value: "Driving" },
                                        { key: 'chassis', text: "Şasi", value: "Chassis" },
                                        { key: 'other', text: "Diğer", value: "Other" },
                                        ]}
                                        value={sectionDropdown}
                                        onChange={(event, { value }) => {
                                            if (value != null) {
                                                handleSectionDropdown(value);
                                            }
                                        }}
                                    />
                                </div>
                            }

                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button   confirm-button' onClick={() => { handleNewEntry() }} type='submit' value='Ekle' />
                            </div>

                        </div>
                    
                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                {clearFilters()}

                                {/* <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                        <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' /> */}
                            </div>

                            : <div className="VideoCardList" >
                                {tableState == 4 ?
                                    <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                        <h1>Lütfen bir hizmet seçiniz.</h1>
                                    </div>
                                    : tableState == 3 ?
                                        <table style={{ padding: ' 15px 0 0 0 ' }}>
                                            <thead style={{ background: '#32C5FF' }}>
                                                <tr>
                                                    <th>Bölüm</th>
                                                    <th>Ürün Grubu</th>
                                                    {/* <th>Dosya Adı</th> */}
                                                    <th>Hizmet</th>
                                                    <th>Kaldır</th>
                                                    <th>Güncelle</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredIntroRecommendations.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                                    .map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{item.section}</td>
                                                            <td>{item.vehicleType == 'Truck' ? 'Kamyon' : 'Otobüs'}</td>
                                                            {/* <td>{item.fileUrl}</td> */}
                                                            <td>Tavsiye/Öneri</td>
                                                            <td className="" style={{ padding: '0', width: '60px' }}>
                                                                <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleRemove((item.id).toString(), 'Recommendation') }} ><i style={{ margin: '0px 0px 4px 0px', color: 'red' }} className="x list-icon icon"></i></button>
                                                                {/* <input className='button close-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Kaldır' /> */}
                                                            </td>
                                                            <td className="" style={{ padding: '0', width: '60px' }}>
                                                                <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString(), 'Recommendation') }} ><i style={{margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                                                {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        : tableState == 2 ?
                                            <table style={{ padding: ' 15px 0 0 0 ' }}>
                                                <thead style={{ background: '#32C5FF' }}>
                                                    <tr>
                                                        <th>Bölüm</th>
                                                        <th>Başlık</th>
                                                        <th>Ürün Grubu</th>
                                                        <th>Açıklama</th>
                                                        {/* <th>Dosya Adı</th> */}
                                                        <th>Hizmet</th>
                                                        <th>Kaldır</th>
                                                        <th>Güncelle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredIntroVideos.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                                        .map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.section == 'Cabin' ? 'Kabin' :
                                                                    item.section == 'Driving' ? 'Sürüş' :
                                                                        item.section == 'Other' ? 'Diğer' :
                                                                            item.section == 'Chassis' ? 'Şasi' : item.section}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.vehicleType == 'Truck' ? 'Kamyon' : 'Otobüs'}</td>
                                                                {/* <td>{item.description}</td> */}
                                                                <td>{item.description.length > 19 ? item.description.substring(0, 10) + '...' + item.description.substring(item.description.length - 5) : item.description}</td>

                                                {/* <td>{item.fileUrl}</td> */}
                                                <td>Tanıtıcı Video</td>
                                                <td className="" style={{ padding: '0', width: '60px' }}>
                                                    <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleRemove((item.id).toString(), 'Video') }} ><i style={{ margin: '0px 0px 4px 0px', color: 'red' }} className="x list-icon icon"></i></button>
                                                    {/* <input className='button close-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Kaldır' /> */}
                                                </td>
                                                <td className="" style={{ padding: '0', width: '60px' }}>
                                                    <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString(), 'Video') }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                                    {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                                </td>
                                            </tr>
                                                        ))}
                            </tbody>
                                            </table>
                    : tableState == 1 ?
                    <table style={{ padding: ' 15px 0 0 0 ' }}>
                        <thead style={{ background: '#32C5FF' }}>
                            <tr>
                                <th>Bölüm</th>
                                <th>Başlık</th>
                                <th>Ürün Grubu</th>
                                <th>Açıklama</th>
                                {/* <th>Dosya Adı</th> */}
                                <th>Hizmet</th>
                                <th>Kaldır</th>
                                <th>Güncelle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredIntroCards.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                .map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.section == 'Cabin' ? 'Kabin' :
                                            item.section == 'Driving' ? 'Sürüş' :
                                                item.section == 'Other' ? 'Diğer' :
                                                    item.section == 'Chassis' ? 'Şasi' : item.section}</td>
                                        <td>{item.title}</td>
                                        <td>{item.vehicleType == 'Truck' ? 'Kamyon' : 'Otobüs'}</td>
                                        <td>{item.description.length > 19 ? item.description.substring(0, 10) + '...' + item.description.substring(item.description.length - 5) : item.description}</td>
                                        {/* <td>{item.description}</td> */}
                                        {/* <td>{item.fileUrl}</td> */}
                                        <td>Tanıtım Kartı</td>
                                        <td className="" style={{ padding: '0', width: '60px' }}>
                                            <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleRemove((item.id).toString(), 'Card') }} ><i style={{  margin: '0px 0px 4px 0px', color: 'red' }} className="x list-icon icon"></i></button>
                                            {/* <input className='button close-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Kaldır' /> */}
                                        </td>
                                        <td className="" style={{ padding: '0', width: '60px' }}>
                                            <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString(), 'Card') }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                            {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    : tableState == 0 &&
                    <table style={{ padding: ' 15px 0 0 0 ' }}>
                        <thead style={{ background: '#32C5FF' }}>
                            <tr>
                                <th>Bölüm</th>
                                <th>Başlık</th>
                                <th>Ürün Grubu</th>
                                <th>Açıklama</th>
                                {/* <th>Dosya Adı</th> */}
                                <th>Hizmet</th>
                                <th>Kaldır</th>
                                <th>Güncelle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {IntroVideos.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                .map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.section == 'Cabin' ? 'Kabin' :
                                            item.section == 'Driving' ? 'Sürüş' :
                                                item.section == 'Other' ? 'Diğer' :
                                                    item.section == 'Chassis' ? 'Şasi' : item.section}</td>
                                        <td>{item.title}</td>
                                        <td>{item.vehicleType == 'Truck' ? 'Kamyon' : 'Otobüs'}</td>
                                        <td>{item.description.length > 19 ? item.description.substring(0, 10) + '...' + item.description.substring(item.description.length - 5) : item.description}</td>
                                        {/* <td>{item.description}</td> */}
                                        {/* <td>{item.fileUrl}</td> */}
                                        <td>Tanıtıcı Video</td>
                                        <td className="" style={{ padding: '0', width: '60px' }}>
                                            <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleRemove((item.id).toString(), 'Video') }} ><i style={{  margin: '0px 0px 4px 0px', color: 'red' }} className="x list-icon icon"></i></button>
                                            {/* <input className='button close-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Kaldır' /> */}
                                        </td>
                                        <td className="" style={{ padding: '0', width: '60px' }}>
                                            <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString(), 'Video') }} ><i style={{  margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                            {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                                }
                </div>}
            </div>
            {pageCount < 1 || tableState == 4 ?
                <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                :
                <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
            }
        </div >
        {/* <div className='FooterContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px' , background:'#32C5FF' }}>
                </div> */}


            </div >
        </>
    )
})