import { makeAutoObservable } from "mobx";
import { DeleteIntroDTO, IntroCardListDTO } from "../models/models";
import agent from "../api/agent";

export default class CommonIntroStore {

    selectedIntro: DeleteIntroDTO | undefined = undefined;

    loadingInitial = false;
    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    deleteIntro = async (req: DeleteIntroDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Intros.deleteIntro(req);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }
}