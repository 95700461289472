export interface IntroCardListDTO {
  introCardList: IntroCardDTO[];
}

export interface IntroCardDTO {
  id: string;
  vehicleType: string;
  section: string;
  title: string;
  description: string;
  imageUrl: string;
  fileUrl: string;
  createdAt: string;
}


export interface IntroVideoListDTO {
  introVideoList: IntroVideoDTO[];
}

export interface IntroVideoDTO {
  id: string;
  vehicleType: string;
  section: string;
  title: string;
  description: string;
  imageUrl: string;
  fileUrl: string;
  createdAt: string;
}

export interface IntroRecommendationListDTO {
  introRecommendationList: IntroRecommendationDTO[];
}

export interface IntroRecommendationDTO {
  id: string;
  vehicleType: string;
  section: string;
  fileUrl: string;
  createdAt: string;
}


export class DeleteIntroDTO {
  id: string = '';
  type: string = '';


  constructor(
  ) {
    this.id = '';
    this.type = '';
  }
}


export interface RestingPlaceListDTO {
  restingPlaceList: RestingPlaceDTO[];
}

export interface RestingPlaceDTO {
  id: string;
  image1: string;
  image2: string;
  image3: string;
  region: string;
  name: string;
  phone: string;
  openingTime: string;
  closingTime: string;
  shareUrl: string;
  latitude: string;
  longitude: string;
  discountForAppUser: boolean;
  discountPercentageForAppUser: string;
  serviceList: ServiceListDTO;
}

export interface ServiceListDTO {
  forEach(arg0: (element: any) => void): any;
  length(length: any): unknown;
  restingPlaceServiceTypeId: string;
  restingPlaceId: string;
  restingPlace: string;
  restingPlaceServiceType: {
    category: string;
    icon: string;
    name: string;
    id: string;
  }
};

export interface RestingPlaceServiceListDTO {
  RestingPlaceServiceTypeId: string;
  name: string;
}

export class DeleteRestingPlaceDTO {
  id: string = '';


  constructor(
  ) {
    this.id = '';
  }
}


export interface MarketingDTO {
  id: string;
  name: string;
  dataType: string;
  vehicleType: string;
  status: string;
  startDate: string;
  finishDate: string;
  cancelDate: string;
  kaClickCount: string;
  nkaClickCount: string;
  driverClickCount: string;
  sshClickCount: string
}

export class CancelMarketingDTO {
  id: string = '';
  constructor(
  ) {
    this.id = '';
  }
}

export interface DetailMarketingDTO {
  id: string;
  title: string;
  name: string;
  vehicleType: string;
  image: string;
  thumbnail: string;
  startDate: string;
  finishDate: string;
  cancelDate: string;
  description: string;
  formUrl: string;
  isCompanyNew: boolean;
  userDetail: MarketingUserDetailDTO;
}

export interface MarketingUserDetailDTO {
  username: string;
  name: string;
  surname: string;
  createdAt: string;
}

export interface ProductDTO {
  id: string;
  vehicleType: string;
  contentType: string;
  title: string;
  description: string;
  imageUrl: string;
  fileUrl: string;
  order: string;
}

export class UpdateProductOrderDTO {
  id: string = '';
  order: number = 0;
  constructor(
  ) {
    this.id = '';
    this.order = 0;
  }
}

export interface RepairShopDTO {
  id: string;
  title: string;
  phone: string;
  shareUrl: string;
  city: string;
}


export interface RepairShopDetailDTO {
  id: string;
  title: string;
  phone: string;
  shareUrl: string;
  city: string;
  latitude: string;
  longitude: string;
  vehicleType: string;
}

export class DeleteRepairShopDTO {
  id: string = '';


  constructor(
  ) {
    this.id = '';
  }
}

export class UpdateRetrofitDTO {
  fileUrl: string = '';


  constructor(
  ) {
    this.fileUrl = '';
  }
}