// import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { CodeInfo, UserFormValues } from '../../app/models/user'
import { toast } from 'react-toastify';
import InputErrorMessage from '../components/inputErrorMessage';
import { OpenIdConfig } from '../../config';
import { useHistory, useLocation } from 'react-router-dom';

export default observer(function LoginForm() {
    const { userStore } = useStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isUserNameEmpty, setIsUserNameEmpty] = useState<boolean>(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState<boolean>(false);

    const location = useLocation();
    const history = useHistory();

    const backgroundImage = {
        Image: '../assets/placeholder.png',
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get("code");

        if (code) {
            console.log(code);
            let codeInfo: CodeInfo = { code: code };
            userStore.openIdLogin(codeInfo);
        }
    }, [location.search, history]);

    const login = () => {
        const redirectUri = encodeURIComponent(OpenIdConfig.RedirectUri);
        const authUrl = `${OpenIdConfig.AuthUrl}?client_id=${OpenIdConfig.ClientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid`;
        window.location.href = authUrl;
    };

    function handleFormSubmit() {
        try {
            let errorExist: boolean = false;
            setIsSubmitting(true);

            if (!userName && userName.trim() == '') {
                setIsUserNameEmpty(true);
                errorExist = true;
            }

            if (!password && password.trim() == '') {
                setIsPasswordEmpty(true);
                errorExist = true;
            }

            if (errorExist)
                return;

            setIsSubmitting(false);
            let userLogin: UserFormValues = { userName: userName, password: password };
            userStore.login(userLogin);
        }
        catch (error: any) {
            toast.error(error.ErrorMessage);
            console.log(error);
        }
        finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/background.jpg'})` }}
                className='loginBackground'
            >
                <div className='loginContainer'>
                    <div>
                        <div className='loginFormContainer'>
                            <div className="loginFormBox">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='loginTitle'>Customer Portal Admin Ekranları</span>
                                </div>

                                <div style={{ marginTop: '30px' }}>
                                    <div>
                                        <span className='loginFormParameterTitle'>KULLANICI ADI</span>
                                    </div>
                                    <div>
                                        <InputErrorMessage inError={isUserNameEmpty} error='Kullanıcı adı boş bırakılamaz !' />
                                        <input className='loginInputUserName' name='userName' placeholder='Kullanıcı Adı'
                                            onChange={(event) => {
                                                setUserName(event.target.value);
                                                setIsUserNameEmpty(false);
                                            }}
                                            onKeyPress={(event) => {
                                                if (event.key == 'Enter')
                                                    handleFormSubmit();
                                            }}
                                            value={userName} />
                                    </div>
                                </div>

                                <div style={{ marginTop: '20px' }}>
                                    <div>
                                        <span className='loginFormParameterTitle'>ŞİFRE</span>
                                    </div>
                                    <div>
                                        <InputErrorMessage inError={isPasswordEmpty} error='Şifre boş bırakılamaz !' />
                                        <input className='loginInputUserName' name='password' placeholder='Şifre' type='password'
                                            onChange={(event) => {
                                                setPassword(event.target.value);
                                                setIsPasswordEmpty(false);
                                            }} value={password}
                                            onKeyPress={(event) => {
                                                if (event.key == 'Enter')
                                                    handleFormSubmit();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ marginTop: '30px' }}>
                                        <input className='button' onClick={() => { handleFormSubmit() }} type='submit' value='GİRİŞ YAPIN' />
                                    </div>

                                    <div style={{ marginTop: '30px' }}>
                                        <input className='samlButton' onClick={() => { login() }} type='submit' value='Sign in with SAML' />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

})