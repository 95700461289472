import { observer } from 'mobx-react-lite';
import { Image, Modal } from 'semantic-ui-react';
import { useStore } from '../../stores/store';

export default observer(function ModalContainer() {
    const { modalStore } = useStore();

    return (
        <Modal open={modalStore.modal.open} onClose={modalStore.closeModal} >
            <Modal.Content>
                <div className='modalContainer'>
                    <div className='modalHeader'>
                        <div className='modalTitle'><span>{modalStore.modal.modalTitle}</span></div>
                        <div className="modalCloseButton" 
                        onClick={() => { modalStore.modal.open = false }}
                        >
                            <Image className='closeButonSvg' src={`/assets/closeicon.svg`} />
                        </div>
                    </div>
                    <hr className='modalLine'></hr>
                    <div className="modalBodyContainer">{modalStore.modal.body}</div>
                    <div className='modalFooter'></div>
                </div>
            </Modal.Content>
        </Modal>
    )
})