import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { RepairShopDTO, CancelMarketingDTO } from '../../app/models/models';
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import { toast } from 'react-toastify'
import { history } from "../..";
import CreateRepairShop from "./CreateRepairShop";
import UpdateRepairShop from "./UpdateRepairShops";

export default observer(function RepairShopsIndex() {
    const { repairShopStore } = useStore();
    const [repairShops, setRepairShops] = useState<RepairShopDTO[]>([]);
    const [filteredRepairShops, setFilteredRepairShops] = useState<RepairShopDTO[]>([]);
    const [search, setSearch] = useState('');
    const [cityDropdown, setCityDropdown] = useState('');


    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);
    const [createRepairShopModal, setCreateRepairShopModal] = useState<boolean>(false);
    const [updateRepairShopModal, setUpdateRepairShopModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    const listPerPage = 5;

    const cityOptions = [
        { key: 'Adana', text: "Adana", value: "Adana" },
        { key: 'Adıyaman', text: "Adıyaman", value: "Adıyaman" },
        { key: 'Afyonkarahisar', text: "Afyonkarahisar", value: "Afyonkarahisar" },
        { key: 'Ağrı', text: "Ağrı", value: "Ağrı" },
        { key: 'Amasya', text: "Amasya", value: "Amasya" },
        { key: 'Ankara', text: "Ankara", value: "Ankara" },
        { key: 'Antalya', text: "Antalya", value: "Antalya" },
        { key: 'Artvin', text: "Artvin", value: "Artvin" },
        { key: 'Aydın', text: "Aydın", value: "Aydın" },
        { key: 'Balıkesir', text: "Balıkesir", value: "Balıkesir" },
        { key: 'Bilecik', text: "Bilecik", value: "Bilecik" },
        { key: 'Bingöl', text: "Bingöl", value: "Bingöl" },
        { key: 'Bitlis', text: "Bitlis", value: "Bitlis" },
        { key: 'Bolu', text: "Bolu", value: "Bolu" },
        { key: 'Burdur', text: "Burdur", value: "Burdur" },
        { key: 'Bursa', text: "Bursa", value: "Bursa" },
        { key: 'Çanakkale', text: "Çanakkale", value: "Çanakkale" },
        { key: 'Çankırı', text: "Çankırı", value: "Çankırı" },
        { key: 'Çorum', text: "Çorum", value: "Çorum" },
        { key: 'Denizli', text: "Denizli", value: "Denizli" },
        { key: 'Diyarbakır', text: "Diyarbakır", value: "Diyarbakır" },
        { key: 'Edirne', text: "Edirne", value: "Edirne" },
        { key: 'Elazığ', text: "Elazığ", value: "Elazığ" },
        { key: 'Erzincan', text: "Erzincan", value: "Erzincan" },
        { key: 'Erzurum', text: "Erzurum", value: "Erzurum" },
        { key: 'Eskişehir', text: "Eskişehir", value: "Eskişehir" },
        { key: 'Gaziantep', text: "Gaziantep", value: "Gaziantep" },
        { key: 'Giresun', text: "Giresun", value: "Giresun" },
        { key: 'Gümüşhane', text: "Gümüşhane", value: "Gümüşhane" },
        { key: 'Hakkâri', text: "Hakkâri", value: "Hakkâri" },
        { key: 'Hatay', text: "Hatay", value: "Hatay" },
        { key: 'Isparta', text: "Isparta", value: "Isparta" },
        { key: 'Mersin', text: "Mersin", value: "Mersin" },
        { key: 'İstanbul', text: "İstanbul", value: "İstanbul" },
        { key: 'İzmir', text: "İzmir", value: "İzmir" },
        { key: 'Kars', text: "Kars", value: "Kars" },
        { key: 'Kastamonu', text: "Kastamonu", value: "Kastamonu" },
        { key: 'Kayseri', text: "Kayseri", value: "Kayseri" },
        { key: 'Kırklareli', text: "Kırklareli", value: "Kırklareli" },
        { key: 'Kırşehir', text: "Kırşehir", value: "Kırşehir" },
        { key: 'Kocaeli', text: "Kocaeli", value: "Kocaeli" },
        { key: 'Konya', text: "Konya", value: "Konya" },
        { key: 'Kütahya', text: "Kütahya", value: "Kütahya" },
        { key: 'Malatya', text: "Malatya", value: "Malatya" },
        { key: 'Manisa', text: "Manisa", value: "Manisa" },
        { key: 'Kahramanmaraş', text: "Kahramanmaraş", value: "Kahramanmaraş" },
        { key: 'Mardin', text: "Mardin", value: "Mardin" },
        { key: 'Muğla', text: "Muğla", value: "Muğla" },
        { key: 'Muş', text: "Muş", value: "Muş" },
        { key: 'Nevşehir', text: "Nevşehir", value: "Nevşehir" },
        { key: 'Niğde', text: "Niğde", value: "Niğde" },
        { key: 'Ordu', text: "Ordu", value: "Ordu" },
        { key: 'Rize', text: "Rize", value: "Rize" },
        { key: 'Sakarya', text: "Sakarya", value: "Sakarya" },
        { key: 'Samsun', text: "Samsun", value: "Samsun" },
        { key: 'Siirt', text: "Siirt", value: "Siirt" },
        { key: 'Sinop', text: "Sinop", value: "Sinop" },
        { key: 'Sivas', text: "Sivas", value: "Sivas" },
        { key: 'Tekirdağ', text: "Tekirdağ", value: "Tekirdağ" },
        { key: 'Tokat', text: "Tokat", value: "Tokat" },
        { key: 'Trabzon', text: "Trabzon", value: "Trabzon" },
        { key: 'Tunceli', text: "Tunceli", value: "Tunceli" },
        { key: 'Şanlıurfa', text: "Şanlıurfa", value: "Şanlıurfa" },
        { key: 'Uşak', text: "Uşak", value: "Uşak" },
        { key: 'Van', text: "Van", value: "Van" },
        { key: 'Yozgat', text: "Yozgat", value: "Yozgat" },
        { key: 'Zonguldak', text: "Zonguldak", value: "Zonguldak" },
        { key: 'Aksaray', text: "Aksaray", value: "Aksaray" },
        { key: 'Bayburt', text: "Bayburt", value: "Bayburt" },
        { key: 'Karaman', text: "Karaman", value: "Karaman" },
        { key: 'Kırıkkale', text: "Kırıkkale", value: "Kırıkkale" },
        { key: 'Batman', text: "Batman", value: "Batman" },
        { key: 'Şırnak', text: "Şırnak", value: "Şırnak" },
        { key: 'Bartın', text: "Bartın", value: "Bartın" },
        { key: 'Ardahan', text: "Ardahan", value: "Ardahan" },
        { key: 'Iğdır', text: "Iğdır", value: "Iğdır" },
        { key: 'Yalova', text: "Yalova", value: "Yalova" },
        { key: 'Karabük', text: "Karabük", value: "Karabük" },
        { key: 'Kilis', text: "Kilis", value: "Kilis" },
        { key: 'Osmaniye', text: "Osmaniye", value: "Osmaniye" },
        { key: 'Düzce', text: "Düzce", value: "Düzce" },
    ]

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {

            await repairShopStore.loadRepairShops();
            if (repairShopStore.repairShops) {
                setRepairShops(JSON.parse(JSON.stringify(repairShopStore.repairShops)));
                setFilteredRepairShops(JSON.parse(JSON.stringify(repairShopStore.repairShops)));

                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(repairShopStore.repairShops)))).length / listPerPage))
                setLoading(false);
            }
        }
        fetchData()
            .catch(console.error);
    }, [])

    const handleFilter = () => {
        if (search == '' && cityDropdown == '') {
            toast.error("En az bir adet arama kriteri giriniz.");
        }
        else {
            var rp = repairShops.filter((item) => {
                return search?.toLowerCase() == '' ? item : item.title?.toLowerCase().includes(search);
            }).filter((item) => {
                return cityDropdown == 'all' || cityDropdown == '' ? item : item.city?.toLowerCase() == cityDropdown.toLowerCase();
            })
            setCurrentPage(1);
            setPageCount(Math.ceil(rp.length / listPerPage))
            setFilteredRepairShops(rp);
            if (rp.length == 0) {
                toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
                setIsThereData(false);

            }
        }

    }

    const clearFilters = () => {
        setCurrentPage(1);
        setIsThereData(true);
        setFilteredRepairShops(repairShops);
        setPageCount(Math.ceil(repairShops.length / listPerPage));
        setSearch('');
        setCityDropdown('');
    }

    const closeCreateRepairShopModal = () => {
        setCreateRepairShopModal(false);
    }

    const closeUpdateRepairShopModal = () => {
        setUpdateRepairShopModal(false);
    }



    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    async function ConfirmRemoveSubmit() {
        try {
            let rid = new CancelMarketingDTO();
            rid.id = elementId;
            var res = await repairShopStore.delete(rid);
            if (res === 200) {
                setRemoveConfirmModalOpen(false);
                toast.success('Dosya başarıyla kaldırılmıştır.')
                await setTimeout(() => history.go(0), 1000);
            }
            else {
                toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
            }
        }
        catch (error) {
            //console.log(error);
        }
    }


    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }



    const handleUpdate = (id: string) => {
        setElementId(id);
        setUpdateRepairShopModal(true);

    }


    const handleSetSearch = (result: any) => {
        setSearch(result)
        setCurrentPage(1);
    }

    const handleCityDropdown = (value: any) => {
        setCityDropdown(value.toString());
    }



    if (repairShopStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>
    if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='RepairShopsBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'500px'} body={<CreateRepairShop handleClose={closeCreateRepairShopModal} />} modalTitle='Servis Ekleme' open={createRepairShopModal} closeModal={closeCreateRepairShopModal} />
                    <ModalInnerContainer width={'500px'} body={<UpdateRepairShop elementId={elementId} handleClose={closeUpdateRepairShopModal} />} modalTitle='Servis Detay' open={updateRepairShopModal} closeModal={closeUpdateRepairShopModal} />

                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Mola yerini kaldırmak istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Tamam"
                        onCancel={handleCancel}
                        onConfirm={() => { ConfirmRemoveSubmit(); }}
                        size='mini'
                    />

                    <div className="" style={{ background: 'white' }}>
                        <div className="approverFilter">
                            <div className="repairShopSearch" style={{ width: '180px', marginRight: '15px' }} >
                                <p>Servis Ara</p>

                                <div className="ui category search" style={{ width: '180px' }}>
                                    <div className="ui icon input" style={{ width: '180px ' }} >
                                        <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Ara" onChange={(event) => handleSetSearch(event.target.value)} value={search}></input>
                                        <i className="search icon"></i>
                                    </div>
                                    <div className="results"></div>
                                </div>
                            </div>
                            <div className="cityDropdown " style={{ width: '300px', marginRight: '15px' }} >
                                <p>Şehir Seçiniz</p>
                                <Dropdown style={{
                                    width: '300px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    search
                                    options={cityOptions}
                                    value={cityDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            handleCityDropdown(value);
                                        }
                                    }}
                                />

                            </div>

                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button  history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button  confirm-button' onClick={() => { setCreateRepairShopModal(true) }} type='submit' value='Ekle' />
                            </div>

                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>

                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>

                            : <div className="VideoCardList" >
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>Servis Adı</th>
                                            <th>Şehir</th>
                                            <th>Servis Tel No</th>
                                            <th>Detay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRepairShops.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.title}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.phone}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                        {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                    {pageCount < 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
                {/* <div className='FooterContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px' , background:'#32C5FF' }}>
                </div> */}


            </div >
        </>
    )
})