import { observer } from 'mobx-react-lite';
import { Tab } from 'semantic-ui-react';
import styled from "styled-components";
import StoryIndex from './story/StoryIndex';
import CampaignIndex from './campaign/CampaignIndex';
import ProductIndex from './retrofit/ProductIndex';
import CompanyNewIndex from './companyNew/CompanyNewIndex';
import RepairShopsIndex from '../repairShop/RepairShopsIndex';

const LoadingWrapper = styled.div`
  margin-top:50px;
  margin-bot:50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default observer(function MarketingDashboard() {
    const panes = [
        { menuItem: 'Storyler', render: () => <Tab.Pane > <StoryIndex /> </Tab.Pane>, },
        { menuItem: 'Kampanyalar', render: () => <Tab.Pane><CampaignIndex /></Tab.Pane> },
        { menuItem: 'Ürünler- Servis Sözleşmesi', render: () => <Tab.Pane> <ProductIndex/> </Tab.Pane> },
        { menuItem: 'Mercedesten Haberler', render: () => <Tab.Pane> <CompanyNewIndex/>  </Tab.Pane> },
        { menuItem: 'Servis', render: () => <Tab.Pane><RepairShopsIndex/> </Tab.Pane> },
    ]

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>PAZARLAMA</span>
                </div>
            </div>


            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <Tab panes={panes} />

            </div >
        </>
    )
})