import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import { history } from "../..";
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';

interface Props {
  handleClose: any;
}

export default observer(function CreateRepairShop(props: Props) {
  const { repairShopStore } = useStore();
  const { createRepairShop } = repairShopStore;

  const [repairShopName, setRepairShopName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
  const [isLongitudeError, setIsLongitudeError] = useState<boolean>(false);
  const [isLatitudeError, setIsLatitudeError] = useState<boolean>(false);
  const [isUrlError, setIsUrlError] = useState<boolean>(false);
  const [isProductDropdownError, setIsProductDropdownError] = useState<boolean>(false);
  const [productDropdown, setProductDropdown] = useState('');

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);


  const [url, setUrl] = useState('');
  const [cityDropdown, setCityDropdown] = useState('');
  const [isCityDropdownError, setIsCityDropdownError] = useState<boolean>(false);

  const cityOptions = [
    { key: 'Adana', text: "Adana", value: "Adana" },
    { key: 'Adıyaman', text: "Adıyaman", value: "Adıyaman" },
    { key: 'Afyonkarahisar', text: "Afyonkarahisar", value: "Afyonkarahisar" },
    { key: 'Ağrı', text: "Ağrı", value: "Ağrı" },
    { key: 'Amasya', text: "Amasya", value: "Amasya" },
    { key: 'Ankara', text: "Ankara", value: "Ankara" },
    { key: 'Antalya', text: "Antalya", value: "Antalya" },
    { key: 'Artvin', text: "Artvin", value: "Artvin" },
    { key: 'Aydın', text: "Aydın", value: "Aydın" },
    { key: 'Balıkesir', text: "Balıkesir", value: "Balıkesir" },
    { key: 'Bilecik', text: "Bilecik", value: "Bilecik" },
    { key: 'Bingöl', text: "Bingöl", value: "Bingöl" },
    { key: 'Bitlis', text: "Bitlis", value: "Bitlis" },
    { key: 'Bolu', text: "Bolu", value: "Bolu" },
    { key: 'Burdur', text: "Burdur", value: "Burdur" },
    { key: 'Bursa', text: "Bursa", value: "Bursa" },
    { key: 'Çanakkale', text: "Çanakkale", value: "Çanakkale" },
    { key: 'Çankırı', text: "Çankırı", value: "Çankırı" },
    { key: 'Çorum', text: "Çorum", value: "Çorum" },
    { key: 'Denizli', text: "Denizli", value: "Denizli" },
    { key: 'Diyarbakır', text: "Diyarbakır", value: "Diyarbakır" },
    { key: 'Edirne', text: "Edirne", value: "Edirne" },
    { key: 'Elazığ', text: "Elazığ", value: "Elazığ" },
    { key: 'Erzincan', text: "Erzincan", value: "Erzincan" },
    { key: 'Erzurum', text: "Erzurum", value: "Erzurum" },
    { key: 'Eskişehir', text: "Eskişehir", value: "Eskişehir" },
    { key: 'Gaziantep', text: "Gaziantep", value: "Gaziantep" },
    { key: 'Giresun', text: "Giresun", value: "Giresun" },
    { key: 'Gümüşhane', text: "Gümüşhane", value: "Gümüşhane" },
    { key: 'Hakkâri', text: "Hakkâri", value: "Hakkâri" },
    { key: 'Hatay', text: "Hatay", value: "Hatay" },
    { key: 'Isparta', text: "Isparta", value: "Isparta" },
    { key: 'Mersin', text: "Mersin", value: "Mersin" },
    { key: 'İstanbul', text: "İstanbul", value: "İstanbul" },
    { key: 'İzmir', text: "İzmir", value: "İzmir" },
    { key: 'Kars', text: "Kars", value: "Kars" },
    { key: 'Kastamonu', text: "Kastamonu", value: "Kastamonu" },
    { key: 'Kayseri', text: "Kayseri", value: "Kayseri" },
    { key: 'Kırklareli', text: "Kırklareli", value: "Kırklareli" },
    { key: 'Kırşehir', text: "Kırşehir", value: "Kırşehir" },
    { key: 'Kocaeli', text: "Kocaeli", value: "Kocaeli" },
    { key: 'Konya', text: "Konya", value: "Konya" },
    { key: 'Kütahya', text: "Kütahya", value: "Kütahya" },
    { key: 'Malatya', text: "Malatya", value: "Malatya" },
    { key: 'Manisa', text: "Manisa", value: "Manisa" },
    { key: 'Kahramanmaraş', text: "Kahramanmaraş", value: "Kahramanmaraş" },
    { key: 'Mardin', text: "Mardin", value: "Mardin" },
    { key: 'Muğla', text: "Muğla", value: "Muğla" },
    { key: 'Muş', text: "Muş", value: "Muş" },
    { key: 'Nevşehir', text: "Nevşehir", value: "Nevşehir" },
    { key: 'Niğde', text: "Niğde", value: "Niğde" },
    { key: 'Ordu', text: "Ordu", value: "Ordu" },
    { key: 'Rize', text: "Rize", value: "Rize" },
    { key: 'Sakarya', text: "Sakarya", value: "Sakarya" },
    { key: 'Samsun', text: "Samsun", value: "Samsun" },
    { key: 'Siirt', text: "Siirt", value: "Siirt" },
    { key: 'Sinop', text: "Sinop", value: "Sinop" },
    { key: 'Sivas', text: "Sivas", value: "Sivas" },
    { key: 'Tekirdağ', text: "Tekirdağ", value: "Tekirdağ" },
    { key: 'Tokat', text: "Tokat", value: "Tokat" },
    { key: 'Trabzon', text: "Trabzon", value: "Trabzon" },
    { key: 'Tunceli', text: "Tunceli", value: "Tunceli" },
    { key: 'Şanlıurfa', text: "Şanlıurfa", value: "Şanlıurfa" },
    { key: 'Uşak', text: "Uşak", value: "Uşak" },
    { key: 'Van', text: "Van", value: "Van" },
    { key: 'Yozgat', text: "Yozgat", value: "Yozgat" },
    { key: 'Zonguldak', text: "Zonguldak", value: "Zonguldak" },
    { key: 'Aksaray', text: "Aksaray", value: "Aksaray" },
    { key: 'Bayburt', text: "Bayburt", value: "Bayburt" },
    { key: 'Karaman', text: "Karaman", value: "Karaman" },
    { key: 'Kırıkkale', text: "Kırıkkale", value: "Kırıkkale" },
    { key: 'Batman', text: "Batman", value: "Batman" },
    { key: 'Şırnak', text: "Şırnak", value: "Şırnak" },
    { key: 'Bartın', text: "Bartın", value: "Bartın" },
    { key: 'Ardahan', text: "Ardahan", value: "Ardahan" },
    { key: 'Iğdır', text: "Iğdır", value: "Iğdır" },
    { key: 'Yalova', text: "Yalova", value: "Yalova" },
    { key: 'Karabük', text: "Karabük", value: "Karabük" },
    { key: 'Kilis', text: "Kilis", value: "Kilis" },
    { key: 'Osmaniye', text: "Osmaniye", value: "Osmaniye" },
    { key: 'Düzce', text: "Düzce", value: "Düzce" },
  ]




  useEffect(() => {
    const fetchData = async () => {


    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }




  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }

  async function ConfirmUploadSubmit() {
    try {
      toast.success('Değişiklikler başarıyla kaydedildi.');
      setUploadConfirmModalOpen(false);
      await setTimeout(() => history.go(0), 1000);
    }
    catch (error) {
      //console.log(error);
    }

  }


  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;

      if (repairShopName.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }
      if (url.length <= 0) {
        setIsUrlError(true)
        errorExist = true;
      }
      if (latitude.length <= 0) {
        setIsLatitudeError(true)
        errorExist = true;
      }
      if (longitude.length <= 0) {
        setIsLongitudeError(true)
        errorExist = true;
      }
      if (phoneNumber.length <= 0) {
        setIsPhoneError(true)
        errorExist = true;
      }

      if (cityDropdown.length <= 0) {
        setIsCityDropdownError(true)
        errorExist = true;
      }

      if (errorExist) {
        return;
      }
      const formData = new FormData();



      if (true) {
        formData.append('Title', repairShopName);
        formData.append('Phone', phoneNumber);
        formData.append('Latitude', latitude);
        formData.append('Longitude', longitude);
        formData.append('ShareUrl', url);
        formData.append('City', cityDropdown);

        var result = await createRepairShop(formData).then(() => {
          props.handleClose(false);
          toast.success('Yeni dosya yüklenmiştir.')
        });
        await setTimeout(() => history.go(0), 1000);
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handlePhoneNumber = (e: any) => {
    let myRate = e.target.value.replace(/0/, '').replace(/\(|\)/g, "").replace(/\D/g, '');
    setPhoneNumber(myRate);

    setIsPhoneError(false);
  }

  const handlePhoneKeyDown = (e: any) => {
    if (phoneNumber.length < 1 && e.key == '0') {
      let mpn = phoneNumber;

    }
  }



  const coordinatesLongKeyUp = (event: any) => {
    event.target.value = event.target.value.replace('.', ',').replace(/[^0-9,]/g, '').replace(/(\..*)\./g, '$1')
    setLongitude(event.target.value);
  }

  const coordinatesLatKeyUp = (event: any) => {
    event.target.value = event.target.value.replace('.', ',').replace(/[^0-9,]/g, '').replace(/(\..*)\./g, '$1')
    setLatitude(event.target.value);
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <Confirm className='RepairShopConfirmModal'
        open={uploadConfirmModalOpen}
        content='Değişiklikleri kaydetmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmUploadSubmit(); }}
        size='mini'
      />
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ padding: '0 10px' }}>
          <div className="inputWrap">
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Servis Adı </div>
            <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
            <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setRepairShopName(event.target.value); setIsNameError(false); }} value={(repairShopName == null) ? '' : repairShopName} />

          </div>

          <div className="coordinatesWrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <div className="latitude" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Enlem (x)</div>
              <InputErrorMessage inError={isLatitudeError} error='Bu alan boş bırakılamaz!' />
              <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => {
                  setIsLatitudeError(false);
                  setLatitude(event.target.value);
                }}
                onKeyUp={coordinatesLatKeyUp}
                value={(latitude == null) ? '' : latitude} />

            </div>
            <div className="longitude" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Boylam (y)</div>
              <InputErrorMessage inError={isLongitudeError} error='Bu alan boş bırakılamaz!' />
              <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => {
                  setIsLongitudeError(false);
                  setLongitude(event.target.value);
                }}
                onKeyUp={coordinatesLongKeyUp}
                value={(longitude == null) ? '' : longitude} />
            </div>

          </div>

          <div className="inputWrap">
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Servis Telefon Numarası </div>
            <InputErrorMessage inError={isPhoneError} error='Bu alan boş bırakılamaz!' />
            <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id="repairShopPhoneNumber"
              onChange={(event) => { handlePhoneNumber(event) }} onKeyDown={(event) => { handlePhoneKeyDown(event) }}
              maxLength={13}
              value={(phoneNumber == null) ? '0('
                : phoneNumber.length >= 4 ? '0(' + phoneNumber.substring(0, 3) + ')' + phoneNumber.substring(3,) : '0(' + phoneNumber} />
          </div>

          <div className="coordinatesWrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="dropdown">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Şehir Seçiniz</div>
              <InputErrorMessage inError={isCityDropdownError} error='Bu alan boş bırakılamaz!' />
              <div className="cityDropdown " style={{ width: '200px', margin: '10px 0 ' }} >
                <Dropdown style={{
                  width: '200px',
                  backgroundColor: 'rgb(242,242,242)',
                  height: '43px',
                  margin: '0 auto',
                  fontSize: '16px',
                }}
                  placeholder='Seçiniz'
                  fluid
                  selection
                  search
                  options={cityOptions}
                  value={cityDropdown}
                  onChange={(event, { value }) => {
                    if (value != null) {
                      setCityDropdown(value.toString());
                      setIsCityDropdownError(false)

                    }
                  }}
                />
              </div>

            </div>
            <div className="productDropdown " style={{ width: '180px'}} >
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
              <InputErrorMessage inError={isProductDropdownError} error='Bu alan boş bırakılamaz!' />
              <div className="cityDropdown " style={{ width: '150px', margin: '10px 0 ' }} >
              <Dropdown style={{
                  width: '150px',
                  backgroundColor: 'rgb(242,242,242)',
                  height: '43px',
                  margin: '0 auto',
                  fontSize: '16px',
                }}
                  placeholder='Seçiniz'
                  fluid
                  selection
                  options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                  { key: 'bus', text: "Otobüs", value: "Bus" },
                  ]}
                  value={productDropdown}
                  onChange={(event, { value }) => {
                    if (value != null) {
                      setProductDropdown(value.toString());
                      setIsProductDropdownError(false);
                    }
                  }}
                />

              </div>

            </div>
        
          </div>
          <div className="inputWrap">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Servis Linki</div>
              <InputErrorMessage inError={isUrlError} error='Bu alan boş bırakılamaz!' />
              <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => { setUrl(event.target.value); setIsUrlError(false); }} value={(url == null) ? '' : url} />

            </div>
        </div>

      </div>

      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', float: 'right' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
        <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Ekle' />

      </div>
    </>


  )

})