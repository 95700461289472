import { observer } from 'mobx-react-lite';
// import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'semantic-ui-react';

interface Props {
    open: boolean;
    closeModal: any;
    body: JSX.Element | null;
    modalTitle: string;
    width: string;
}

export default observer(function ModalInnerContainer(props: Props) {

    return (
        <Modal open={props.open} style={{ width: props.width }} >
            <Modal.Content>
                <div className='modalContainer'>
                    <div className='modalHeader'>
                        <div className='modalTitle'><span>{props.modalTitle}</span></div>
                        <div className="modalCloseButton"
                            onClick={props.closeModal}
                        >
                            <Image className='closeButonSvg' src={`/assets/closeicon.svg`} />
                        </div>
                    </div>
                    <hr className='modalLine'></hr>
                    <div className="modalBodyContainer">{props.body}</div>
                    <div className='modalFooter'></div>
                </div>
            </Modal.Content>
        </Modal>
    )
})