import { observer } from 'mobx-react-lite';
import { Container } from 'semantic-ui-react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

const LoadingWrapper = styled.div`
  margin-top:50px;
  margin-bot:50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default observer(function Dashboard() {
    return (
        <>
         <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>ANA SAYFA</span>
                </div>
                </div>
                
        <div className="placeholder" style={{ width: '100%', height:'500px', backgroundColor:'white' }}>

        </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            


                    {/* <Container style={{display: 'flex', flexDirection: 'row' , justifyContent:'center'}}>
                        <Link to="/intros" className="dashboardButton" style={{ color: 'white' }}>Aracıma Dair</Link>
                        <Link to="/restingPlaces" className="dashboardButton" style={{ color: 'white' }}>Kısa Bir Mola</Link>
                    </Container> */}

                <div className='FooterContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px' , background:'#32C5FF' }}>
                </div>

             
            </div >
        </>
    )
})