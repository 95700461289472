import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import { ProductDTO, UpdateProductOrderDTO, } from '../../../app/models/models';
import ModalInnerContainer from "../../../app/common/modals/ModalInnerContainer";
import { history } from "../../..";
import DetailProduct from "./DetailProduct";
import CreateProduct from "./CreateProduct";
import Retrofit from "./Retrofit";

export default observer(function ProductIndex() {

    const { productStore } = useStore();
    const [products, setProducts] = useState<ProductDTO[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<ProductDTO[]>([]);

    const [isAlreadyCancelled, setIsAlreardCancelled] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState('');



    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [title, setTitle] = useState('');
    const [productDropdown, setProductDropdown] = useState('');

    const [createProductModal, setCreateProductModal] = useState<boolean>(false);
    const [detailProductModal, setDetailProductModal] = useState<boolean>(false);
    const [retrofitModal, setRetrofitModal] = useState<boolean>(false);
    

    const [publishDate, setPublishDate] = useState<any>('');
    const [removeDate, setRemoveDate] = useState<any>('');


    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');


    const [recDropdownOptions, setRecDropdownOptions] = useState<{ key: string; text: string; value: string; }[]>([]);
    const [selectedOrderId, setSelectedOrderId] = useState('');

    const listPerPage = 5;

    useEffect(() => {
        const fetchData = async () => {
            await productStore.loadProducts();
            if (productStore.products) {
                setProducts(JSON.parse(JSON.stringify(productStore.products)));
                setFilteredProducts(JSON.parse(JSON.stringify(productStore.products)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(productStore.products)))).length / listPerPage))
            }

            setCurrentPage(1);
        }
        fetchData()
            .catch(console.error);
    }, [])

    const handleRecDropdownOptions = () => {
        let items = [];
        items.push({ key: 'all', text: 'Tümü', value: 'all' });

        for (let i = 0; i < products.length; i++) {
            if (items.filter(item => item.key === products[i].title).length == 0) {
                items.push({ key: products[i].title, text: products[i].title, value: products[i].title });
            }
        }
        setRecDropdownOptions(items)
    }



    const handleFilter = () => {
        setCurrentPage(1);
        handleRecDropdownOptions()
        let dts = products.filter((item) => {
            return productDropdown == '' ? item : item.vehicleType.toLowerCase() == productDropdown.toLowerCase();
        }).filter((item) => {
            return title?.toLowerCase() == '' || title?.toLowerCase() == 'all' ? item : item.title?.toLowerCase() == title.toLowerCase();
        })
        setFilteredProducts(dts);
        setCurrentPage(1);
        setPageCount(Math.ceil(dts.length / listPerPage))
        if (dts.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            setIsThereData(false)
        }

    }

    const clearFilters = () => {
        handleProductDropdown('');
        handleTitleDropdown('');
        setRemoveDate('');
        setPublishDate('');
        setCurrentPage(1);
        setIsThereData(true);
        setFilteredProducts(products);
        setPageCount(Math.ceil(products.length / listPerPage))
    }


    const handleProductDropdown = (value: any) => {
        setProductDropdown(value.toString());
    }

    const handleTitleDropdown = (value: any) => {
        setTitle(value.toString());
    }


    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }


    const closeDetailProductModal = () => {
        setDetailProductModal(false);
    }

    
    const closeRetrofitModal = () => {
        setRetrofitModal(false);
    }
    
    const closeCreateProductModal = () => {
        setCreateProductModal(false);
    }


    const handleDetail = (id: string) => {
        setElementId(id);
        setDetailProductModal(true);
    }



    async function handleClick(id: string) {

        let rid = new UpdateProductOrderDTO();
        rid.id = id;
        rid.order = parseInt(selectedOrder);
        var res = await productStore.updateProductOrder(rid);
        if (res == 200) {
            setRemoveConfirmModalOpen(false);
            toast.success('Dosya başarıyla kaldırılmıştır.')
            await setTimeout(() => history.go(0), 1000);
        }
        else {
            toast.success('İşlem sırasında Bir Problem Meydana geldi')
        }
    };

    const showOrderButton = (id: string, show: boolean, event: any) => {
        const element = document.getElementById('btn-' + id);
        const tdElement = document.getElementById('td-' + id);
        if (show) {
            element!.style.display = 'block';
        }

        else {
            element!.style.display = 'none';
        };

        console.log(event.target.getAttribute('data-id'))
        const input = event.target.getAttribute('data-id');

    }

    if (productStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='ApproverBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'600px'} body={<CreateProduct handleClose={closeCreateProductModal} />} modalTitle='Servis Sözleşmesi Ürünü' open={createProductModal} closeModal={closeCreateProductModal} />
                    <ModalInnerContainer width={'600px'} body={<DetailProduct handleClose={closeDetailProductModal} elementId={elementId} isAlreadyCancelled={isAlreadyCancelled} />} modalTitle='Servis Sözleşmesi Ürünü' open={detailProductModal} closeModal={closeDetailProductModal} />
                    <ModalInnerContainer width={'400px'} body={<Retrofit handleClose={closeRetrofitModal} elementId={elementId} />} modalTitle='Retrofit' open={retrofitModal} closeModal={closeRetrofitModal} />

                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Sırayı güncellemek istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Güncelle"
                        onConfirm={() => handleClick(selectedOrderId)}
                        onCancel={handleCancel}
                        size='mini'
                    />
                    <div className="" style={{ background: 'white' }}>
                        <div className="approverFilter">

                            <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={productDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setProductDropdown(value.toString());
                                        }
                                    }}
                                />
                            </div>
                            <div className="titleDropdown " style={{ width: '320px', marginRight: '15px' }} >
                                <p>Başlık</p>
                                <Dropdown style={{
                                    width: '320px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Başlık Seçiniz.'
                                    search
                                    fluid
                                    selection
                                    options={recDropdownOptions}

                                    value={title}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setTitle(value.toString());
                                        }
                                    }}
                                    onClick={() => {
                                        handleRecDropdownOptions();
                                    }}
                                />

                            </div>

                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button   confirm-button' onClick={() => { setCreateProductModal(true); }} type='submit' value='Ekle' />
                                <input style={{ marginLeft: '8px' }} className='button   retrofit-button' onClick={() => { setRetrofitModal(true); }} type='submit' value='Retrofit' />
                            </div>

                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>
                            :

                            <div className="dataTable">

                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>

                                        <tr>
                                            <th>Ürün Grubu</th>
                                            <th>Başlık</th>
                                            <th>Dosya Tipi</th>
                                            <th>Sıralama</th>
                                            <th>Detay</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {filteredProducts
                                            .sort(function (a, b) {
                                                if (a.vehicleType.toLowerCase() < b.vehicleType.toLowerCase()) return -1;
                                                if (a.vehicleType.toLowerCase() > b.vehicleType.toLowerCase()) return 1;
                                                return 0;
                                            })
                                            .sort((first, second) => {
                                                return first.order > second.order ? 1 : -1;
                                            })
                                            .slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.vehicleType}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.contentType}</td>
                                                    <td
                                                        style={{ width: '195px' }}
                                                        id={'td-' + item.id}
                                                        onMouseEnter={e => { showOrderButton(item.id, true, e) }} onMouseLeave={e => { showOrderButton(item.id, false, e) }}
                                                    >
                                                        <div >
                                                            <input type="text" data-id={'input-' + item.id}
                                                                onChange={(e => { setSelectedOrder(e.target.value) })}
                                                                placeholder={item.order}
                                                                className="orderInput"
                                                                style={{ width: '50px' }} />
                                                            <button
                                                                className="confirm-button button"
                                                                style={{ display: 'none' }}
                                                                onClick={() => { setSelectedOrderId(item.id); setRemoveConfirmModalOpen(true) }} data-id={item.id}
                                                                id={'btn-' + item.id}>
                                                                Güncelle
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleDetail((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    {pageCount <= 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})