import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
// import React from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, Dropdown } from 'semantic-ui-react';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';

export default observer(function NavBar() {
    const { userStore: { user, logout, isLoggedIn } } = useStore();
    return (
        <div className='navbarContainer'>
            <div style={{ display: 'flex' }}>
                <NavLink to="/" activeStyle={{ fontWeight: "bold", color: "red" }} style={{ display: 'flex' }}>
                    <img src={`/assets/DAIMLER_LOGO.png`} alt='logo' width='200px'></img>
                </NavLink>


                <div style={{ marginLeft: '20px' }}>
                    {<Menu.Item as={NavLink} className='navBarLink' to='/intros' content='Aracıma Dair' name='Aracıma Dair' />}
                    {<Menu.Item as={NavLink} className='navBarLink' to='/restingPlaces' content='Kısa Bir Mola' name='Kısa Bir Mola' />}
                    {<Menu.Item as={NavLink} className='navBarLink' to='/marketing' content='Pazarlama' name='Pazarlama' />}
                </div>

            </div>

            <div className='userNameContainer'>
                <Dropdown className='userNameLink' pointing='top left' text={user?.username}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={logout} text='Çıkış Yap' icon='power' />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
})