import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";

interface Props {
  handleClose: any;
}

export default observer(function CreateProduct(props: Props) {
  const { productStore } = useStore();
  const { createProduct } = productStore;

  const [title, setTitle] = useState('');
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [order, setOrder] = useState('');

  const [contentType, setContentType] = useState('');
  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);
  const [image, setImage] = useState<File>();
  const [video, setVideo] = useState<File>();
  const [attatchmentFile, setAttatchmentFile] = useState<File>();
  const [thumbnail, setThumbnail] = useState<File>();
  const [videoFileName, setVideoFileName] = useState('');
  const [attatchmentFileFileName, setAttatchmentFileFileName] = useState('');
  const [imageFileName, setImageFileName] = useState('');
  const [thumbnailFileName, setThumbnailFileName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [selectedAttatchmentFile, setSelectedAttatchmentFile] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [productDropdown, setProductDropdown] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isProductDropdownError, setIsProductDropdownError] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [isThumbnailError, setIsThumbnailError] = useState<boolean>(false);

  const [description, setDescription] = useState('');
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);
  const [isOrderError, setIsOrderError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  const handleThumbnail = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];
      if (file && file.type.substring(0, 5) === "image") {
        setThumbnail(file);
        setThumbnailFileName(filename)
        setSelectedThumbnail(URL.createObjectURL(e.target.files[0]));
      }

    }
  }

  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }

  async function ConfirmUploadSubmit() {
    try {
      toast.success('Değişiklikler başarıyla kaydedildi.');
      setUploadConfirmModalOpen(false);
      await setTimeout(() => history.go(0), 1000);
    }
    catch (error) {
      //console.log(error);
    }

  }


  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      const formData = new FormData();

      if (title.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }
      if (description.length <= 0) {
        setIsDescriptionError(true)
        errorExist = true;
      }
      if (order.length <= 0) {
        setIsOrderError(true)
        errorExist = true;
      }

      if (productDropdown.length <= 0) {
        setIsProductDropdownError(true)
        errorExist = true;
      }
      if (!image && !attatchmentFile && !video) {
        setIsImageError(true);
        errorExist = true;
      }
      if (!thumbnail && video) {
        setIsThumbnailError(true)
        errorExist = true;
      }

      if (errorExist) {
        return;
      }

      if (true) {
        formData.append('VehicleType', productDropdown);
        formData.append('ContentType', contentType);
        formData.append('Title', title);
        formData.append('Description', description);

        if (image && !video && !attatchmentFile) {
          formData.append('ImageUrl', '');
          formData.append('FileUrl', image);
        }
        else if (!image && video && !attatchmentFile && thumbnail) {
          formData.append('ImageUrl', thumbnail);
          formData.append('FileUrl', video);
        }
        else if (!image && !video && attatchmentFile) {
          formData.append('ImageUrl', '');
          formData.append('FileUrl', attatchmentFile);
        }
        formData.append('Order', order);

        console.log('başarılı')
        var result = await createProduct(formData).then(() => {
          props.handleClose(false);
          toast.success('Yeni dosya yüklenmiştir.')
        });
        await setTimeout(() => history.go(0), 1000);
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }




  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }



  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];

      if (file && file.type.substring(0, 5) === "image") {
        if (file.size > 1000000) {
          toast.error("Seçilen resmin boyutu 1MB'ın üzerindedir");
        }
        else {
          setContentType('image')
          setImage(file);
          setImageFileName(filename)
          setSelectedImage(URL.createObjectURL(e.target.files[0]));
          setVideo(undefined);
          setVideoFileName('');
          setSelectedVideo('');
          setAttatchmentFile(undefined);
          setAttatchmentFileFileName('');
          setSelectedAttatchmentFile('');
          setThumbnail(undefined);
          setSelectedThumbnail('');
          setThumbnailFileName('');
        }
      }
      else if (file && fileExtension(file.name.trim()).toUpperCase() === "MP4") {
        if (file.size > 5000000) {
          toast.error("Seçilen videonun boyutu 5MB'ın üzerindedir");
        }
        else {
          let filename = e.target.files[0].name;
          setContentType('video')
          setVideo(file);
          setVideoFileName(filename);
          setSelectedVideo(URL.createObjectURL(e.target.files[0]));
          setImage(undefined);
          setImageFileName('')
          setSelectedImage('');
          setAttatchmentFile(undefined);
          setAttatchmentFileFileName('');
          setSelectedAttatchmentFile('');
        }
      }
      else if (file && fileExtension(file.name.trim()).toUpperCase() === "PDF") {
        let filename = e.target.files[0].name;
        setContentType('pdf')
        setAttatchmentFile(file);
        setAttatchmentFileFileName(filename);
        setSelectedAttatchmentFile(URL.createObjectURL(e.target.files[0]));
        setImage(undefined);
        setImageFileName('')
        setSelectedImage('');
        setVideo(undefined);
        setVideoFileName('');
        setSelectedVideo('');
        setThumbnail(undefined);
        setSelectedThumbnail('');
        setThumbnailFileName('');
      }
    }
  }
  const handleDuration = (e: any) => {

    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];

      var blobObj = new Blob([file!], { type: "video/mp4" });
      const url = URL.createObjectURL(blobObj);


      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.muted = true;
        const source = document.createElement("source");
        source.src = url; //--> blob URL
        source.type = 'video/mp4'; //--> blob URL
        video.preload = "metadata";
        video.appendChild(source);

        video.onloadedmetadata = function () {
          resolve(video.duration)
          setVideoDuration(Math.floor(video.duration));
          if (video.duration > 30) {
            toast.error("Seçilen videonun süresi 30 saniyenin üzerindedir");
            setVideo(undefined);
            setVideoFileName('');
            setSelectedVideo('');
            setThumbnail(undefined);
            setSelectedThumbnail('');
            setThumbnailFileName('');
          }
        };
      });
    }


  }



  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <Confirm className='RestingPlaceConfirmModal'
        open={uploadConfirmModalOpen}
        content='Değişiklikleri kaydetmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmUploadSubmit(); }}
        size='mini'
      />
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Servis Sözleşmesi Ürünü Başlık </div>
            <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
            <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); setIsNameError(false); }} value={(title == null) ? '' : title} />
          </div>
          <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <InputErrorMessage inError={isProductDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection

                options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                { key: 'bus', text: "Otobüs", value: "Bus" },
                ]}
                value={productDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProductDropdown(value.toString());
                    setIsProductDropdownError(false);
                  }
                }}
              />

            </div>

          </div>
        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Servis Sözleşmesi Ürünü Açıklama </div>
          <InputErrorMessage inError={isDescriptionError} error='Bu alan boş bırakılamaz!' />
          <textarea name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
            onChange={(event) => { setDescription(event.target.value); setIsDescriptionError(false); }} value={(description == null) ? '' : description} />
        </div>


        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }}>
          <div style={{ marginLeft: '190px', marginBottom: '10px' }}>
            <InputErrorMessage inError={isImageError} error='Lütfen bir dosya seçin!' />
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Dosya Ekle
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                onChange={(event) => { handleFile(event); setIsImageError(false); handleDuration(event); }}
                type='file'
                accept="image/*,video/mp4,application/pdf"
              />
            </div>
          </label>

          {selectedImage ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b>
                    {imageFileName.length > 19 ? imageFileName.substring(0, 10) + '...' + imageFileName.substring(imageFileName.length - 5) : imageFileName}</b></div>
                </div>
                <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage} />
              </div>

            </div>
            : <div />}

          {selectedAttatchmentFile ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b>
                    {attatchmentFileFileName.length > 19 ? attatchmentFileFileName.substring(0, 10) + '...' + attatchmentFileFileName.substring(attatchmentFileFileName.length - 5) : attatchmentFileFileName}</b></div>
                </div>
              </div>

            </div>
            : <div />}
          {selectedVideo != '' ?

            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" >
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName">Dosya Adı : <b>
                    {videoFileName.length > 19 ? videoFileName.substring(0, 10) + '...' + videoFileName.substring(videoFileName.length - 5) : videoFileName}</b></div>


                </div>



              </div>
            </div>
            : <div />}

          {selectedVideo != '' &&
            <div className="thumbWrapper" style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }} >
              <div style={{ marginLeft: '170px', marginBottom: '10px' }}>
                <InputErrorMessage inError={isThumbnailError} error='Lütfen bir dosya seçin!' />
              </div>
              <label htmlFor="thumbInput"
                style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                className="btn">
                <div className="entryModalButton dark-gray-button">
                  Thumbnail Ekle
                  <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                  <input className='button dark-gray-button'
                    id='thumbInput'
                    style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                    onChange={(event) => { handleThumbnail(event); setIsThumbnailError(false); }}
                    type='file'
                    accept="image/*"
                  />
                </div>
              </label>
              {selectedThumbnail ?
                <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column', marginLeft: '105px' }}>
                    <div className="selectedFileDesc" style={{ padding: '10px' }}>
                      <div className="selectedFileName" >Dosya Adı : <b>
                        {thumbnailFileName.length > 19 ? thumbnailFileName.substring(0, 10) + '...' + thumbnailFileName.substring(thumbnailFileName.length - 5) : thumbnailFileName}</b></div>

                    </div>
                    <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedThumbnail} />
                  </div>

                </div>
                : <div />}
            </div>
          }

        </div>




      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px ' }}>
        <div className="inputWrap" style={{ padding: '0 20px', width: '38%', float: 'left' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Ürün Sırası </div>
          <InputErrorMessage inError={isOrderError} error='Bu alan boş bırakılamaz!' />
          <input type="number" name="" style={{ margin: '10px 0', width: '30%', marginLeft: '17px' }} id=""
            onChange={(event) => { setOrder(event.target.value); setIsOrderError(false) }} value={(order == null) ? '' : order} />
        </div>
        <div className="" style={{ display: 'flex', height: '36px', marginTop: '34px', float: 'right' }}>

          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
          <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Ekle' />

        </div>
      </div>
    </>


  )

})
