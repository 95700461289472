import { CSSTransition } from "react-transition-group"

interface Props {
    error: string,
    inError: boolean,
}

export default function InputErrorMessage(prop: Props) {

    return (
        <div style={{ position:'relative' }}>
            <CSSTransition in={prop.inError} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                <div className='errorMessageEmbed'>{prop.error}</div>
            </CSSTransition>
        </div>
    )
}
export  function InputErrorMessageSpics(prop: Props) {

    return (
        <div style={{ position:'relative', left:'5px' }}>
            <CSSTransition in={prop.inError} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                <div className='errorMessageSpics'>{prop.error}</div>
            </CSSTransition>
        </div>
    )
}