import { makeAutoObservable } from "mobx";
import { IntroRecommendationDTO, IntroRecommendationListDTO } from "../models/models";
import agent from "../api/agent";

export default class IntroRecommendationStore {

    introRecommendations: IntroRecommendationListDTO | undefined = undefined;

    loadingInitial = false;
    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadIntroRecommendations = async () => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.IntroRecommendation.listRecommendations();
            if (result) {
                this.introRecommendations = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getIntroRecommendationDetail = async (id: string): Promise<IntroRecommendationDTO | null> => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.IntroRecommendation.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createRecommendation = async (recommendation: FormData) => {
        try {
            return await agent.IntroRecommendation.createRecommendation(recommendation);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

    updateRecommendation = async (recommendation: FormData) => {
        try {
            return await agent.IntroRecommendation.updateRecommendation(recommendation);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

}